import { create } from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      accessToken: null,
      userData: null,
      setAccessToken: (token) => set({ accessToken: token }),
      setUserData: (data) => set({ userData: data }),
      clearAccessToken: () => set({ accessToken: null }),
      clearUserData: () => set({ userData: null }),
      clear: () => set({ accessToken: null, userData: null }),
    }),
    {
      name: "user-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useStore;
