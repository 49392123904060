import React, { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Menu } from "antd";
import { PageTitleContext } from "PageTitleContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuFoldOutlined, CloseOutlined } from "@ant-design/icons";
import CustomButton from "components/UiComponents/CustomButton";
import CustomRow from "components/UiComponents/CustomRow/index.jsx";
import CustomColumn from "components/UiComponents/CustomColumn/index.jsx";
import { routePath } from "utils/Constants/routes.constant";
import logo from "../../assets/Images/logo.png";
import "./Header.less";

const TopHeader = () => {
  const { Header } = Layout;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pageTitle } = useContext(PageTitleContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigate = (item) => navigate(`/${item.key}`);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const items = [
    {
      label: t("rfps"),
      linkTo: routePath.MAINSCREEN,
      key: "rfps",
    },
    {
      label: t("agencies"),
      linkTo: routePath.AGENCIES,
      key: "agencies",
    },
    {
      label: t("businesses"),
      linkTo: routePath.BUSINESSES,
      key: "businesses",
    },
    {
      label: t("bookmark"),
      linkTo: routePath.BOOKMARK,
      key: "bookmark",
    },
  ];

  const currentKey = location.pathname.split("/")[1] || "rfps"; // Default to 'rfps' if no path is selected

  return (
    <>
      <Header className="headerWrapper">
        <CustomRow>
          <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="menuWrapper">
              <Link to={routePath.MAINSCREEN} className="headerLogo">
                <img src={logo} width={175} height={35} />
              </Link>
              <Menu
                onClick={handleNavigate}
                mode="horizontal"
                selectedKeys={[currentKey]}
                items={items}
                style={{ flex: 1, minWidth: 0 }}
              />
            </div>
          </CustomColumn>
        </CustomRow>
      </Header>
      <div className="mobileHeaderWrapper">
        <div className="mobileMenuTop">
          <Link to={routePath.MAINSCREEN} className="headerLogo">
            <img src={logo} width={175} height={35} />
          </Link>
          <CustomButton onClick={toggleMenu}>
            <MenuFoldOutlined />
          </CustomButton>
        </div>

        <div className={`headerMenu ${menuOpen ? `open` : ""}`}>
          <div className="mobileMenuTop">
            <CustomButton onClick={toggleMenu}>
              <CloseOutlined />
            </CustomButton>
          </div>
          <Menu
            onClick={handleNavigate}
            selectedKeys={[currentKey]}
            items={items}
          />
        </div>
      </div>
    </>
  );
};

export default memo(TopHeader);
