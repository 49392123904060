import React from "react";
import { Navigate } from "react-router-dom";
import { routePath } from "utils/Constants/routes.constant";
import useStore from "store/useStore";

/**
 * @param {{ children: React.ReactNode, requiredPermissions?: string[] }} props
 * @returns
 */
const ProtectedRoute = ({ children }) => {
  const { accessToken } = useStore();

  // Redirecting to login if there's no accessToken
  if (!accessToken) {
    return <Navigate to={`/${routePath.AUTH}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
