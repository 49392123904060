import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routePath } from "utils/Constants/routes.constant";
import MainLayout from "layout";

import FullScreenLoader from "components/CustomComponents/FullScreenLoader";
import ProtectedRoute from "./ProtectedRoute";

const Auth = lazy(() => import("screens/Auth"));
const AuthCallback = lazy(() => import("screens/Auth/AuthCallback"));
const Rfps = lazy(() => import("screens/Rfps"));
const Agencies = lazy(() => import("screens/Agencies"));
const NotFound = lazy(() => import("screens/NotFound"));
const Businesses = lazy(() => import("screens/Businesses"));
const Bookmark = lazy(() => import("screens/Bookmark"));
const RfpsListDetail = lazy(() => import("screens/Rfps/Detail"));
const AgenciesSubDetail = lazy(() => import("screens/Agencies/SubDetail"));
const AgenciesDetail = lazy(() => import("screens/Agencies/AgenciesDetail"));
const BusinessDetail = lazy(() => import("screens/Businesses/BusinessDetail"));

function AppRoutes() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {/* AUTH  */}
        <Route path={routePath.AUTH}>
          <Route index element={<Auth />} />
          <Route path={`${routePath.CALLBACK}`}>
            <Route index element={<AuthCallback />} />
          </Route>
        </Route>

        <Route element={<MainLayout />}>
          {/* Redirect from root ("/") to RFPS */}
          <Route path="/" element={<Navigate to={routePath.RFPS} replace />} />

          {/* RFPS  */}
          <Route path={routePath.RFPS}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Rfps />
                </ProtectedRoute>
              }
            />
            <Route path={`${routePath.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <RfpsListDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* Agencies  */}
          <Route path={`${routePath.AGENCIES}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Agencies />
                </ProtectedRoute>
              }
            />
            <Route path={`${routePath.SUB_DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AgenciesSubDetail />
                  </ProtectedRoute>
                }
              />
              <Route path={`${routePath.DETAIL}/:id`}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <AgenciesDetail />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* Businesses  */}
          <Route path={`${routePath.BUSINESSES}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Businesses />
                </ProtectedRoute>
              }
            />
            <Route path={`${routePath.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <BusinessDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* Bookmark  */}
          <Route path={`${routePath.BOOKMARK}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Bookmark />
                </ProtectedRoute>
              }
            />

            <Route path={`${routePath.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <RfpsListDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* 404  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
